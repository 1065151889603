<template>
  <div class="index">
    <div class="banner">
      <el-carousel class="bannerBox">
        <el-carousel-item v-for="(item, id) in banner" :key="id">
          <el-image class="img" :src="item.fileId" @click="imgtopage(item)">
            <div slot="error" class="image-slot">
              <img class="img" src="../../assets/imges/error/1.png" />
            </div>
          </el-image>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div>
      <div class="header indexheader">
        <div class="title">强大、安全、稳定的产品</div>
        <div class="info">
          满足不同领域应用需求，让企业获得精准用户提升服务品质
        </div>
      </div>
      <div class="product">
        <div
          style="
            display: fixed;
            flex-direction: row;
            display: -webkit-box;
            align-content: flex-end;
          "
        >
          <img
            src="../../assets/index/left.png"
            class="productleft swiper-button-prev bannerImg"
          />
          <div class="cardlist">
            <div class="swiper-container">
              <div class="swiper-wrapper">
                <div
                  class="swiper-slide card"
                  v-for="(item, index) in productlist"
                  :key="index"
                >
                  <div
                    :style="{
                      backgroundImage: 'url(' + item.backgroundImage + ')',
                    }"
                    class="cardheader"
                  >
                    <div class="cardheader2">
                      <img
                        src="../../assets/index/2.png"
                        class="cardheaderimg"
                      />
                      <div class="cardheadertitle">
                        <div>{{ item.name }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="productmiddle">
                    <el-image class="infourl" :src="item.productIcons">
                      <div slot="error" class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                      </div>
                    </el-image>
                    <div class="info">
                      {{ item.description }}
                    </div>
                  </div>
                  <div class="infobutton">
                    <el-button
                      type="primary"
                      plain
                      @click="topage(item)"
                    ></el-button>
                  </div>
                  <div class="bebottom"></div>
                </div>
              </div>
            </div>
          </div>
          <img
            src="../../assets/index/right.png"
            class="productleft swiper-button-next bannerRight"
          />
        </div>
      </div>
    </div>

    <div
      class="scheme"
      :style="{
        backgroundImage:
          'url(' + categoryIdinfolist[categoryid].backgroundImage + ')',
      }"
    >
      <div class="info">
        <div class="header">解决方案</div>
        <div class="title">{{ categorylisttext }}，邦伲德都有完整解决方案</div>
        <div class="classify">
          <div class="classifyheader">
            <div
              class="classifytext"
              v-for="(item, index) in categorylist"
              :key="index"
              @click="categoryid = index"
              :style="{ background: categoryid == index ? '#2489FF' : '' }"
            >
              {{ item }}
            </div>
          </div>
          <div class="line"></div>
          <div class="content">
            <div class="contenttitle">
              {{ categoryIdinfolist[categoryid].name }}
              <div
                class="contenbutton"
                @click="toschemepage(categoryIdinfolist[categoryid])"
              >
                了解详情
              </div>
            </div>
            <div class="contentinfo">
              {{ categoryIdinfolist[categoryid].description }}
            </div>
            <div class="contenttag">
              <img :src="categoryIdinfolist[categoryid].descriptionImage" />
            </div>
            <div class="contentimg">
              <img :src="categoryIdinfolist[categoryid].cooperationCompany" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="dynamic">
      <div class="header">
        <div class="title">集团新动态</div>
        <!-- <div class="info">全场景全链路的SaaS产品，助力企业数字升级</div> -->
      </div>
      <div class="dynamicheader">
        <span
          v-for="(item, index) in GroupDynamicTypeList"
          :key="index"
          class="centerspan"
          :style="
            item.id == GroupDynamicTypeid
              ? 'border-bottom:4px solid #2F7FFC'
              : ''
          "
          @click="getgetGroupDynamicList(item)"
          >{{ item.name }}</span
        >
      </div>
      <div class="dynamicinfo">
        <div style="display: flex; justify-content: center">
          <div class="dynamicinfotheme">
            <el-image
              :src="dynamicinfo.coverPhoto"
              @click="imgtopage(item)"
              v-if="dynamicinfo.coverPhoto"
            >
              <div slot="error" class="image-slot">
                <img src="../../assets/imges/error/1.png" />
              </div>
            </el-image>
            <img src="../../assets/index/3.png" v-else />
            <div class="dynamicinfothemetitle" @click="toDeail(dynamicinfo)">
              {{ dynamicinfo.title }}
            </div>
            <div class="dynamicinfothemeinfo" @click="toDeail(dynamicinfo)">
              {{ dynamicinfo.brief }}
            </div>
          </div>
          <div class="dynamicinfolist">
            <div
              class="dynamicinfolistback"
              v-for="(item, index) in dynamicinfolist"
              :key="index"
              @click="toDeail(item)"
            >
              <div class="dynamicinfolistcard">
                <div class="dynamicinfolisttime">
                  <div class="year">{{ item.year }}</div>
                  <div class="time">{{ item.time }}</div>
                </div>
                <div class="dynamicinfolistinfo">
                  <div class="dynamicinfoinfotitle">
                    {{ item.title }}
                  </div>
                  <div class="dynamicinfoinfotext">
                    {{ item.brief }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="dynamicbutton">
          <el-button type="primary" @click="todynamic" class="customization"
            >查看更多</el-button
          >
        </div>
      </div>
    </div>

    <div class="certification">
      <div class="header">
        <div class="title">安全、可信合规资质</div>
        <div class="info">全场景全链路的SaaS产品，助力企业数字升级</div>
      </div>
      <div class="certificationinfo">
        <div
          class="certificationlist"
          v-for="(item, index) in certificationlist"
          :key="index"
        >
          <div class="certificationcard" @click="toZizhi(item.id)">
            <el-image :src="item.fileId">
              <div slot="error" class="image-slot">
                <img src="../../assets/imges/error/1.png" />
              </div>
            </el-image>
          </div>
          <div class="certificationtext">{{ item.name }}</div>
        </div>
      </div>
      <div class="certificationbutton">
        <el-button type="primary" @click="toZizhi()" class="customization"
          >查看更多</el-button
        >
      </div>
    </div>

    <div class="distribution">
      <div class="distributioninfo">
        <div class="header">
          <div class="title">全国服务客户分布图</div>
        </div>
        <img
          src="../../assets/index/distribution.png"
          class="distributionimg"
        />
        <div
          style="display: flex; justify-content: center; align-items: flex-end"
        >
          <div class="distributioncard">
            <div class="distributioncardname">合作伙伴</div>
            <div class="distributioncardnum">{{ PlatformList.name }}+</div>
          </div>
          <div class="line"></div>
          <div class="distributioncard">
            <div class="distributioncardname">客户数量</div>
            <div class="distributioncardnum">
              {{ PlatformList.customerNumber }}+
            </div>
          </div>
          <div class="line"></div>
          <div class="distributioncard">
            <div class="distributioncardname">成功案例</div>
            <div class="distributioncardnum">
              {{ PlatformList.successCases }}+
            </div>
          </div>
          <div class="line"></div>
          <div class="distributioncard">
            <div class="distributioncardname">每秒处理订单数</div>
            <div class="distributioncardnum">
              {{ PlatformList.orderProcessingNumber }}W+
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="service">
      <div class="header">
        <div class="title">全方位无忧的专属服务</div>
      </div>
      <div class="serviceinfo">
        <div class="servicecard">
          <div class="servicetitle">
            <img src="../../assets/index/4.png" />
            <span>售前咨询</span>
          </div>
          <div class="servicetext">
            立即致电销售人员进行购买咨询，或预约技术获取全面的技术解决方案
          </div>
          <div class="servicephone">
            <img
              src="../../assets/index/phone.png"
              style="vertical-align: middle"
            />
            <span style="vertical-align: middle">400-0365558</span>
          </div>
          <div class="servicebutton">
            <el-button type="primary">联系我们</el-button>
          </div>
        </div>
        <div class="onlinecard">
          <div class="onlinetitle">
            <img src="../../assets/index/4.png" />
            <span>在线支持</span>
          </div>
          <div class="onlinetext">
            智能诊断，及时回复，为您提供快速便捷的服务体验，欢迎您咨询邦伲德！
          </div>
          <div class="onlinebutton">
            <el-button type="primary" class="customization">立即咨询</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Swiper, { Navigation, Pagination, Scrollbar } from "swiper";
import { SwiperSlide } from "swiper/vue";
import "swiper/swiper.less";
import {
  QualificationTypesList,
  PlatformSetList,
  SolutionList,
  SearchRecommended,
  GroupDynamicTypeList,
  GroupDynamicFrontendQuery,
  QueryIsShow,
} from "../../api/index.js";
import { daytim, yearmonth } from "../../utils/util.js";
Swiper.use([Navigation, Pagination, Scrollbar]);
export default {
  components: {
    Swiper,
  },
  data() {
    return {
      classifyid: "1",
      PlatformList: {},
      // 动态类型列表
      GroupDynamicTypeList: [],
      GroupDynamicTypeid: "",
      // 解决方案类别列表
      categorylist: [],
      categorylisttext: "",
      categoryid: "0",
      // 解决方案信息列表
      categoryIdinfolist: [
        {
          name: "",
          description: "",
        },
      ],
      dynamicinfo: {},
      dynamicinfolist: [],
      certificationlist: [],
      banner: [],
      productlist: [],
    };
  },
  // created() {

  // },
  mounted() {
    // 解决方案
    SolutionList().then((res) => {
      var that = this;
      if (res.data.rows.length > 0) {
        this.categoryIdinfolist = res.data.rows;
        this.categoryIdinfolist.forEach(function (item, index) {
          that.categorylist.push(item.categoryName);
          that.categorylisttext += item.categoryName + "、";
        });
        that.categorylisttext = that.categorylisttext.slice(
          0,
          that.categorylisttext.length - 1
        );
      }
      // this.$forceUpdate();
    });
    // this.$emit('pagename','index')
    this._initSwiper();
    // 轮播图
    QueryIsShow().then((res) => {
      console.log(res, 822);
      this.banner = res.data;
    });

    // 资质类别
    QualificationTypesList({
      PageNo: 1,
      PageSize: 5,
    }).then((res) => {
      this.certificationlist = res.data.rows;
      console.log(this.certificationlist, 963);
    });
    // 全国服务客户分布图
    PlatformSetList().then((res) => {
      this.PlatformList = res.data[0];
    });

    // 推荐产品
    SearchRecommended().then((res) => {
      this.productlist = res.data;
    });
    // 动态类型
    GroupDynamicTypeList().then((res) => {
      this.GroupDynamicTypeList = res.data.splice(1, res.data.length - 1);
      this.getgetGroupDynamicList(this.GroupDynamicTypeList[0]);
    });
  },
  methods: {
    // 查看详情
    toDeail(item) {
      this.$router.push({ path: "/ExhibitionDetails", query: { id: item.id } });
    },
    //
    getgetGroupDynamicList(item) {
      console.log(11, "getgetGroupDynamicList");
      this.GroupDynamicTypeid = item.id;
      GroupDynamicFrontendQuery({
        DynamicTypeId: this.GroupDynamicTypeid,
        PageNo: 1,
        PageSize: 4,
      }).then((res) => {
        if (res.data.items.length > 0) {
          this.dynamicinfo = res.data.items[0];
          let dynamicinfolist = res.data.items.splice(
            1,
            res.data.items.length - 1
          );
          dynamicinfolist.forEach(function (item, index) {
            let time = item.releaseTime.split(" ")[0];
            item.time = yearmonth(new Date(time));
            item.year = daytim(new Date(time));
          });
          this.dynamicinfolist = dynamicinfolist;
        } else {
          this.dynamicinfo = {};
          this.dynamicinfolist = [];
        }
      });
    },
    topage(item) {
      this.$router.push(item.productContentUrl);
    },
    toschemepage(item) {
      this.$router.push(item.planLink);
    },
    imgtopage(item) {
      this.$router.push(item.skipLinks);
    },
    _initSwiper() {
      new Swiper(".swiper-container", {
        slideToClickedSlide: true,
        slidesPerView: 3,
        spaceBetween: 90,
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    },
    // 资质更多
    toZizhi(val) {
      if (val) {
        this.$router.push({ path: "Certification", query: { id: val } });
      } else {
        this.$router.push("Certification");
      }
    },
    todynamic() {
      this.$router.push("dynamic");
    },
  },
};
</script>

<style scoped lang="less">
.customization {
  background: linear-gradient(270deg, #0a89ff 0%, #4371ff 100%);
}

.index {
  width: 100%;
}

.banner {
  height: 100%;
  width: 100%;
  height: 727px;
}

.bannerBox {
  height: 100%;
}

/deep/ .el-carousel__container {
  height: 100% !important;
  object-fit: cover;
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.indexheader {
  padding-top: 60px;
}

.el-button--primary.is-plain {
  color: #409eff;
  background: #fff;
  border-color: #b3d8ff;
}

.header {
  margin: 0 auto;
  text-align: center;
  font-size: 36px;
  font-family: Medium;
  font-weight: 500;
  width: 80%;

  .info {
    font-size: 24px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #9e9e9e;
    margin-top: 31px;
  }
}

.product {
  // margin: 0 auto;
  margin-top: 59px;
  // width: 100%;
  // text-align: center;
  padding-left: 10%;
  padding-right: 10%;

  .cardlist {
    // width: 74%;
    // margin-left: 10%;
    // margin-right: 10%;
    // margin: 0 auto;
    .swiper-container {
      padding: 20px;
      width: 1450px;
    }
  }

  /deep/.card {
    // margin-left: 20px !important;
    margin-right: 70px !important;
  }

  .card:hover {
    .el-button--primary.is-plain {
      color: #fff;
      background: #409eff;
      border-color: #409eff;
    }

    button:after {
      content: "\67e5\770b\66f4\591a";
    }

    .bebottom {
      background-color: #2f7ffc;
    }
  }

  .card {
    button:after {
      content: "\4e86\89e3\8be6\60c5";
    }

    width: 434px !important;
    height: 541px;
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.13);
    border-radius: 10px;

    // margin-right: 70px !important;
    .cardheader {
      height: 118px;
      width: 100%;
      background-size: cover;

      .cardheader2 {
        position: absolute;
        margin: 33px 47px;

        .cardheaderimg {
          vertical-align: top;
          width: 22px;
          height: 22px;
        }

        .cardheadertitle {
          line-height: 1;
          display: inline-block;
          vertical-align: top;
          font-size: 24px;
          font-family: SourceHanSansCN-Bold, SourceHanSansCN;
          font-weight: bold;
          color: #ffffff;
          margin-left: 10px;

          .cardheadertag {
            background: #ffffff;
            border-radius: 4px;
            font-size: 12px;
            font-family: Medium;
            font-weight: 500;
            color: #ff825c;
            padding: 4px 8px;
            margin-top: 13px;
          }
        }
      }
    }

    .productmiddle {
      margin: 36px 47px;

      .infourl {
        width: 72px;
        height: 72px;
        border-radius: 10px;
      }

      .info {
        font-size: 14px;
        font-family: Medium;
        font-weight: 500;
        color: #9e9e9e;
        line-height: 28px;
        margin-top: 18px;

        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5;
        overflow: hidden;
      }
    }

    .infobutton {
      text-align: center;
      position: absolute;
      left: 50%;

      margin-left: -64px;
      bottom: 61px;
      width: 128px;
      /deep/.el-button {
        width: 100%;
      }
    }

    .bebottom {
      border-radius: 0 0 20px 20px;
      width: 338px;
      height: 17px;
      position: absolute;
      top: 540px;
      left: 46px;
    }
  }

  .productleft {
    width: 72px;
    height: 72px;
    margin-top: 15%;
    // z-index: 9;
  }
}

.bannerImg {
  position: absolute;
  left: 5%;
  // top: 70%;
}

.bannerRight {
  position: absolute;
  right: 5%;
  // top: 70%;
}

.scheme {
  background-size: cover;
  width: 100%;
  height: 800px;
  margin-top: 107px;
  color: #ffffff;

  .contenbutton {
    width: 88px;
    height: 30px;
    background-color: #2489ff;
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
    text-align: center;
    display: inline-block;
    float: right;
    cursor: pointer;
  }
  .info {
    width: 80%;
    margin: 0 auto;
    padding-top: 50px;
    // margin-left: 260px;
    text-align: center;

    .title {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      margin-top: 15px;
    }

    .classify {
      text-align: left;
      display: flex;
      margin-top: 60px;

      .classifyheader {
        .classifytext {
          cursor: pointer;
          width: 154px;
          box-sizing: border-box;
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
          padding: 9px 43px;
          margin-bottom: 28px;
          text-align: center;
        }
      }

      .line {
        // width: 3px;
        height: 520px;
        background-color: #666666;
        margin-left: 93px;
        border-right: 1px solid #666666;
      }

      .content {
        margin-left: 102px;

        .contenttitle {
          margin-top: 16px;
          font-size: 24px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          letter-spacing: 4px;
        }

        .contentinfo {
          margin-top: 20px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 30px;
          letter-spacing: 2px;
        }

        .contenttag {
          margin-top: 72px;
          padding-bottom: 47px;
          border-bottom: 1px dashed #666666;
          text-align: center;

          img {
            width: 1080px;
            height: 84px;
          }

          .contenttagtext {
            margin-top: 20px;
          }
        }

        .contentimg {
          margin-top: 51px;
          text-align: center;

          img {
            width: 1078px;
            padding: 0 52px;
          }
        }
      }
    }
  }
}

.dynamic {
  background-color: #f5f5f9;
  padding-top: 118px;

  .dynamicheader {
    text-align: center;
    margin-top: 55px;
    font-size: 18px;
    font-weight: 600;

    .centerspan {
      margin: 128px;
      cursor: pointer;
    }

    span {
      padding-bottom: 10px;
    }
  }

  .dynamicinfo {
    width: 80%;
    margin: 0 auto;
    margin-top: 46px;

    .dynamicinfotheme {
      width: 612px;

      img {
        width: 100%;
      }

      .dynamicinfothemetitle {
        font-size: 24px;
        font-weight: 600;
        margin-top: 32px;
        cursor: pointer;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .dynamicinfothemeinfo {
        font-size: 14px;
        font-weight: 500;
        color: #a2a3a4;
        margin-top: 17px;
        cursor: pointer;
      }
    }

    .dynamicinfolist {
      width: 754px;
      margin-left: 29px;

      .dynamicinfolistback {
        padding: 20px 19px;
        border-radius: 8px;
        cursor: pointer;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        .dynamicinfolistcard {
          cursor: pointer;
          display: flex;

          .dynamicinfolisttime {
            background-color: #fff;
            text-align: center;
            padding: 28px;
            width: 138px;
            border-radius: 7px;

            .year {
              font-size: 40px;
              font-family: SourceHanSansCN-Bold, SourceHanSansCN;
              font-weight: bold;
            }

            .time {
              font-size: 20px;
              font-family: Medium;
              font-weight: 500;
            }
          }

          .dynamicinfolistinfo {
            margin-left: 18px;

            .dynamicinfoinfotitle {
              font-size: 24px;
              font-weight: 600;
              margin-top: 19px;
              width: 548px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .dynamicinfoinfotext {
              height: 56px;
              font-size: 14px;
              font-weight: 500;
              color: #a2a3a4;
              line-height: 28px;
              margin-top: 12px;
            }
          }
        }
      }

      .dynamicinfolistback:hover {
        box-shadow: -2px 1px 20px 0px #e6e6e6;
        .dynamicinfolisttime {
          background-color: #2f7ffc;
          color: #fff;
        }
      }
    }
  }

  .dynamicbutton {
    text-align: center;
    margin-top: 65px;
    padding-bottom: 79px;
  }
}

.certification {
  margin-top: 94px;

  .certificationinfo {
    width: 80%;
    margin: 0 auto;
    margin-top: 82px;
    display: flex;
    justify-content: space-between;

    .certificationlist {
      text-align: center;
      margin-right: 20px;

      .certificationcard {
        box-shadow: 1px 2px 21px 2px #e2e2e2;
        border-radius: 14px;
        padding: 64px 0px;
        width: 224px;
        height: 224px;
        box-sizing: border-box;
        text-align: center;
        /deep/img {
          height: 96px;
          // max-width: 85px;
          // min-width: 77px;
        }
        /deep/.el-image__inner {
          width: auto;
        }
      }

      .certificationtext {
        margin-top: 38px;
        font-size: 26px;
      }
    }
  }

  .certificationbutton {
    text-align: center;
    margin-top: 65px;
    padding-bottom: 79px;
  }
}

.distribution {
  background-color: #f5f5f9;

  .distributioninfo {
    width: 80%;
    margin: 0 auto;
    padding-top: 97px;
    text-align: center;
    padding-bottom: 101px;

    .distributionimg {
      width: 850px;
      padding-top: 52px;
      padding-bottom: 24px;
    }

    .line {
      width: 2px;
      height: 68px;
      background: #d8d8d8;
      margin: 0 71px;
    }

    .distributioncard {
      text-align: left;

      .distributioncardname {
        font-size: 18px;
        font-family: Medium;
        font-weight: 500;
        color: #8c8c8c;
      }

      .distributioncardnum {
        font-size: 71px;
        font-family: Medium;
        font-weight: 500;
        color: #1764dd;
        margin-top: 7px;
      }
    }
  }
}

.service {
  width: 80%;
  margin: 0 auto;
  margin-top: 88px;

  .serviceinfo {
    display: flex;
    justify-content: center;
    margin-top: 92px;
    padding-bottom: 94px;

    .servicecard {
      background-image: url(../../assets/index/service1.png);
      background-size: cover;
      width: 556px;
      height: 184px;
      border-radius: 10px;
      padding: 52px 60px;

      .servicetitle {
        font-size: 24px;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: bold;
        img {
          width: 21px;
        }
        span {
          margin-left: 7px;
        }
      }

      .servicetext {
        margin-top: 29px;
        margin-left: 10px;
        font-size: 16px;
        font-family: Medium;
        font-weight: 500;
        color: #777777;
        line-height: 28px;
        width: 376px;
      }

      .servicephone {
        font-size: 24px;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: bold;
        margin-top: 26px;
        img {
          width: 14px;
        }
        span {
          margin-left: 7px;
          font-size: 20px;
          font-family: Medium;
          font-weight: 500;
          color: #ffda0a;
        }
      }

      .servicebutton {
        // position: absolute;
        text-align: right;
        margin-top: 42px;

        .el-button {
          width: 128px;
          height: 55px;
          background: linear-gradient(270deg, #0a89ff 0%, #4371ff 100%);
        }
      }
    }

    .servicecard:hover {
      background-image: url(../../assets/index/service.png);
      box-shadow: 0px 0px 29px 0px rgba(139, 154, 226, 0.38);
    }

    .onlinecard {
      background-image: url(../../assets/index/online1.png);
      background-size: cover;
      width: 556px;
      height: 184px;
      border-radius: 10px;
      padding: 52px 60px;
      margin-left: 48px;

      .onlinetitle {
        font-size: 24px;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: bold;
        img {
          width: 21px;
        }
        span {
          margin-left: 7px;
        }
      }

      .onlinetext {
        margin-top: 29px;
        margin-left: 10px;
        font-size: 16px;
        font-family: Medium;
        font-weight: 500;
        color: #777777;
        line-height: 28px;
        width: 376px;
      }

      .onlinephone {
        font-size: 24px;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: bold;
        margin-top: 26px;

        span {
          margin-left: 7px;
          font-size: 20px;
          font-family: Medium;
          font-weight: 500;
          color: #ffda0a;
        }
      }

      .onlinebutton {
        // position: absolute;
        text-align: right;
        margin-top: 99px;

        .el-button {
          width: 128px;
          height: 55px;
          background: linear-gradient(270deg, #0a89ff 0%, #4371ff 100%);
        }
      }
    }

    .onlinecard:hover {
      background-image: url(../../assets/index/online.png);
      box-shadow: 0px 0px 29px 0px rgba(139, 154, 226, 0.38);
    }
  }
}
</style>
